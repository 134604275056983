import React, { useRef } from 'react'
import { motion, useScroll } from 'framer-motion'
import LiIcon from './LiIcon'
import AnimatedText from './AnimatedText'
import { Text } from '@nextui-org/react'
//import TransitionEffect from './TransitionEffect'

const Details = ({ type, time, place, info, grade }) => {
    const ref = useRef(null)
    return <li ref={ref} className='my-8 first:mt-0 last:mb-0 w-[60%] mx-auto flex flex-col items-center justify-between'>
        <LiIcon reference={ref} />
        <motion.div initial={{ y: 50 }} whileInView={{ y: 0 }} transition={{ duration: 0.5, type: 'spring' }}>
            <h3 className='capitalize font-bold text-2xl'>
                {type}
            </h3>

            <Text blockquote className='text-justify font-medium w-full'>
                <span className='capitalize font-medium'> {place} | {time}  </span> <br />
                <span className='capitalize font-medium'> GPA: {grade}/4.0 </span> <br /><br />
                {info}</Text>
        </motion.div>
    </li>
}

export default function Education() {
    const ref = useRef(null)
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start end", "center start"]
    })
    return (
        <>
            {/* <TransitionEffect /> */}
            <div className='mt-10'>
                <AnimatedText text="Education" />
                {/* <h2 className='font-bold text-8x1 mb-32 w-full text-center'>
                    Education
                </h2> */}
            </div>
            <div ref={ref} className='w-[75%] mx-auto relative mt-10'>
                <motion.div
                    style={{ scaleY: scrollYProgress }}
                    className='absolute left-9 top-0 w-[4px] h-full bg-dark origin-top'></motion.div>
                <ul className='w-full flex flex-col items-start justify-between ml-4'>
                    <Details
                        type="Master of Science in Software Engineering"
                        time="Aug 2022 - May 2024"
                        grade="3.6"
                        place="San Jose State University, San Jose, California"
                        info="Relevant Coursework: Enterprise Software Platforms, Enterprise Distributed Systems, Enterprise Application Development, Software Systems Engineering, Data Mining"
                    />
                    <Details
                        type="Btech in Computer Science & Engineering"
                        time="Aug 2015 - Apr 2019"
                        grade="3.7"
                        place="B V Raju Institute of Technology, Narsapur, India"
                        info="Relevant Coursework: Object-Oriented Programming in Java, Data Structures and Algorithms, Operating Systems, Database Management Systems, Software Engineering"
                    />
                </ul>
            </div>
        </>
    )
}

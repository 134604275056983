import React from 'react'
import { motion } from 'framer-motion'
import AnimatedText from './AnimatedText'
import Layout from './Layout'

export default function About() {
    const sentence = {
        hidden: { opacity: 1 },
        visible: {
            opacity: 1,
            transition: {
                delay: 0.2,
                staggerChildren: 0.01
            }
        }
    }
    const letter = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0
        }
    }

    const line = 'Hello, my name is Adesh Landge, and I am a graduate student at San Jose State University in the United States. From a young age, I have been captivated by the world of computers and have found great joy in the art of creation. It is this profound interest that has guided me to pursue a fulfilling career in Software Engineering, where I can merge my love for technology with the creative process.\n\nWhile coding occupies a significant portion of my time, I also indulge in another exhilarating passion — watching Test Cricket. There is something truly captivating about witnessing the strategic and enduring battles between teams that keep my adrenaline pumping. It serves as a thrilling escape from the world of programming and provides me with a sense of excitement and inspiration. \n\nWith my unwavering enthusiasm for computers, dedication to honing my software engineering skills, I am constantly seeking opportunities to grow both personally and professionally.';
    return (
        <>
            <div className=' mt-10'>
                <AnimatedText text="About Me" />
                <Layout className='px-80'>                    
                    <motion.h4 className='font-medium indent-10 whitespace-pre-line text-justify' variants={sentence} initial='hidden' animate='visible'>
                    {/* <Text blockquote> */}
                        {line.split("").map((char, index) => {
                            return (
                                <motion.span variants={letter} key={char + "-" + index}>
                                   {char}
                                </motion.span>
                            )
                        })}
                        {/* </Text> */}
                    </motion.h4>
                </Layout>
            </div>
        </>
    )
}
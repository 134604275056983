import React, { useRef } from 'react'
import { motion, useScroll } from 'framer-motion'
import LiIcon from './LiIcon'
import AnimatedText from './AnimatedText'
import { Text } from '@nextui-org/react'
//import TransitionEffect from './TransitionEffect'

const Details = ({ position, company, companyLink, time, address, work }) => {
    const ref = useRef(null)
    return <li ref={ref} className='my-8 first:mt-0 last:mb-0 w-[60%] mx-auto flex flex-col items-center justify-between'>
        <LiIcon reference={ref} />
        <motion.div initial={{ y: 50 }} whileInView={{ y: 0 }} transition={{ duration: 0.5, type: 'spring' }}>
            <h3 className='capitalize font-bold text-2xl'>
                {position}&nbsp; -&nbsp; <a href={companyLink} target='_blank' rel="noreferrer" className='text-primary capitalize'> {company}</a>
            </h3>
            <span className='capitalize font-medium'>{time} | {address}</span>
            {/* <p className='w-full'>{work}</p> */}
            <Text className='indent-10 text-justify' blockquote weight="medium">{work}</Text>
        </motion.div>
    </li>
}

export default function Experience() {
    const ref = useRef(null)
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start end", "center start"]
    })
    return (
        <>
            {/* <TransitionEffect /> */}
            <div className=' mt-10'>
                <AnimatedText text="Experience" />
                {/* <h2 className='font-bold text-8x1 mb-32 w-full text-center'>
                    Experience
                </h2> */}
            </div>
            <div ref={ref} className='w-[75%] mx-auto relative  mt-10'>
                <motion.div
                    style={{ scaleY: scrollYProgress }}
                    className='absolute left-9 top-0 w-[4px] h-full bg-primary origin-top'></motion.div>
                <ul className='w-full flex flex-col items-start justify-between ml-4'>
                    <Details
                        position="Business Technology Analyst"
                        company="Deloitte"
                        companyLink=""
                        time="2021-2022"
                        address="Hyderabad, India"
                        work="Experience the power of Salesforce B2B Commerce Cloud and Order Management System (OMS) like never before! With a deep understanding of these cutting-edge technologies, I have successfully integrated external systems, utilizing REST API and Connected App for seamless operations. Leveraging Flows and Apex, I have meticulously designed and implemented various features, enabling efficient order creation, processing, and inventory management in the Omni-Channel Inventory (OCI) with B2B Commerce Cloud.

                        Witness the transformation as I seamlessly connect OMS with SAP, revolutionizing the way Fulfillment orders are transmitted. By optimizing the order data transfer to SAP by a staggering 50%, I have enhanced efficiency to unparalleled heights."
                    />
                    <Details
                        position="Software Engineer"
                        company="Persistent Systems"
                        companyLink=""
                        time="2019-2021"
                        address="Hyderabad, India"
                        work="With a robust background in Sales Cloud, Experience Cloud, and Service Cloud, I have honed my skills to deliver unparalleled results. Utilizing the power of Apex, Visualforce, Aura, and Lightning Web Components (LWC), I have crafted bespoke solutions that have seamlessly integrated Salesforce with a myriad of platforms such as SAP, CPI, WordPress, Google Analytics, and Microsoft SharePoint.

                        My proficiency in SOQL, SOSL, Webservices, APIs (SOAP and REST), and Batch Processes has elevated my ability to optimize operations and achieve remarkable outcomes. Not only am I well-versed in declarative tools like Flow Builder, Process Builder, and Approval Process, but I also possess the discernment to determine when to leverage programmatic implementations for superior results.
                        
                        A testament to my expertise lies in the creation of captivating user interfaces through the development of reusable LWC components. By harnessing the power of these components, I have revolutionized the user experience, resulting in a staggering 70% improvement in efficiency and a streamlined workflow that fosters productivity."
                    />
                    <Details
                        position="Software Developer Intern"
                        company="Persistent Systems"
                        companyLink="www.google.com"
                        time="2018"
                        address="Pune, India"
                        work="With a focus on creating Single Page Applications (SPAs) using the cutting-edge MEAN stack (MongoDB, Express, Angular, and Node.js), I have mastered the art of crafting immersive digital experiences.  I have built scalable APIs using the power of Node.js, ensuring seamless communication between front-end and back-end systems."
                    />
                </ul>
            </div>
        </>
    )
}
import React, { useRef } from "react";
import { motion, useInView } from 'framer-motion';
import { Container, Row, Col } from '@nextui-org/react';
import { Button } from "@nextui-org/react";
import { Text } from "@nextui-org/react";
import { Image, Link } from '@nextui-org/react';
import SFDC from '../assets/images/SFDC.png';
import './Home.css'
import { DownloadIcon, EmailIcon, GithubIcon, InstagramIcon, LeetCodeIcon, LinkedInIcon, TwitterIcon } from "./Icons";
import Layout from "./Layout";
import Resume from "../assets/docs/AdeshLandge_Resume.pdf"

export default function Home() {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    const displayResume = () => {
        window.open(Resume, '_blank');
    }

    return (
        <>
            <Layout>
                <Container >
                    <div>
                        <Row justify="center">
                            <Col span={12} sm={10} md={8} lg={6}>
                                <motion.div ref={ref} style={{
                                    transform: isInView ? "none" : "translateY(100px)",
                                    opacity: isInView ? 1 : 0,
                                    transition: "all 0.9s 0.5s"
                                }} className="card" >
                                    <Text
                                        h2
                                        size={60}
                                        css={{
                                            textGradient: "45deg, $purple600 -20%, $red600 50%",
                                        }}
                                        weight="semibold"
                                    >
                                        Hi there!
                                    </Text>
                                </motion.div>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={12} sm={10} md={8} lg={6}>
                                <motion.div ref={ref} style={{
                                    transform: isInView ? "none" : "translateY(200px)",
                                    opacity: isInView ? 1 : 0,
                                    transition: "all 0.9s 1.0s"
                                }} className="card" >
                                    <Text
                                        h1
                                        size={80}
                                        css={{
                                            textGradient: "45deg, $purple600 -20%, $red600 50%",
                                        }}
                                        weight="semibold"
                                    >
                                        I'm Adesh Landge.
                                    </Text>
                                </motion.div>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={12} sm={10} md={8} lg={6}>
                                <motion.div ref={ref} style={{
                                    transform: isInView ? "none" : "translateX(200px)",
                                    opacity: isInView ? 1 : 0,
                                    transition: "all 0.9s 1.5s"
                                }}>
                                    <Button rounded shadow="true" iconRight={<DownloadIcon />} color="gradient" auto
                                        onClick={displayResume}>
                                        Download Resume
                                    </Button>
                                </motion.div>
                            </Col>
                        </Row>
                        <br />
                        <Row justify="center">
                            <Col span={12} sm={10} md={8} lg={6}>
                                <div className="flex items-center flex-wrap">
                                    <motion.div ref={ref} style={{
                                        transform: isInView ? "none" : "translateX(-200px)",
                                        opacity: isInView ? 1 : 0,
                                        transition: "all 0.9s 1.5s"
                                    }}>
                                        <Text className="mr-4" color="warning" transform="capitalize" weight="bold" size="$3xl">
                                            Get in touch:
                                        </Text>
                                    </motion.div>
                                    <motion.a href="mailto:adeshlandge1998@gmail.com" target="_blank" className="mr-3"
                                        whileHover={{ y: -4 }}
                                        whileTap={{ scale: 0.9 }}
                                        ref={ref} style={{
                                            transform: isInView ? "none" : "translateX(200px)",
                                            opacity: isInView ? 1 : 0,
                                            transition: "all 0.9s 2.0s"
                                        }}>
                                        <EmailIcon />
                                    </motion.a>
                                    <motion.a href="https://github.com/adeshlandge/" target="_blank" className="mr-3"
                                        whileHover={{ y: -4 }}
                                        whileTap={{ scale: 0.9 }}
                                        ref={ref} style={{
                                            transform: isInView ? "none" : "translateX(200px)",
                                            opacity: isInView ? 1 : 0,
                                            transition: "all 0.9s 2.2s"
                                        }}>
                                        <GithubIcon />
                                    </motion.a>
                                    <motion.a href="https://www.linkedin.com/in/adeshlandge1998/" target="_blank" className="mr-3"
                                        whileHover={{ y: -4 }}
                                        whileTap={{ scale: 0.9 }}
                                        ref={ref} style={{
                                            transform: isInView ? "none" : "translateX(200px)",
                                            opacity: isInView ? 1 : 0,
                                            transition: "all 0.9s 2.4s"
                                        }}>
                                        <LinkedInIcon />
                                    </motion.a>
                                    <motion.a href="https://twitter.com/Adeshlandge" target="_blank" className="mr-3"
                                        whileHover={{ y: -4 }}
                                        whileTap={{ scale: 0.9 }}
                                        ref={ref} style={{
                                            transform: isInView ? "none" : "translateX(200px)",
                                            opacity: isInView ? 1 : 0,
                                            transition: "all 0.9s 2.6s"
                                        }}>
                                        <TwitterIcon />
                                    </motion.a>
                                    <motion.a href="https://www.instagram.com/adeshlandge/" target="_blank" className="mr-3"
                                        whileHover={{ y: -4 }}
                                        whileTap={{ scale: 0.9 }}
                                        ref={ref} style={{
                                            transform: isInView ? "none" : "translateX(200px)",
                                            opacity: isInView ? 1 : 0,
                                            transition: "all 0.9s 2.8s"
                                        }}>
                                        <InstagramIcon />
                                    </motion.a>
                                    <motion.a href="https://leetcode.com/adeshlandge1998/" target="_blank" className="mr-3"
                                        whileHover={{ y: -4 }}
                                        whileTap={{ scale: 0.9 }}
                                        ref={ref} style={{
                                            transform: isInView ? "none" : "translateX(200px)",
                                            opacity: isInView ? 1 : 0,
                                            transition: "all 0.9s 3.0s"
                                        }}>
                                        <LeetCodeIcon />
                                    </motion.a>

                                </div>
                            </Col>
                        </Row>
                                            <br/>
                                            <Image showSkeleton src={SFDC} className='w-full h-auto'></Image>
                    </div>
                </Container>
            </Layout>
        </>
    )
}

import { motion } from 'framer-motion'
import React from 'react'
import { useTheme } from '@nextui-org/react';

const quote = {
    initial: {
        opacity: 1
    },
    animate: {
        opacity: 1,
        transition: {
            delay: 0.5,
            staggerChildren: 0.08,
        }
    }
}

const singleWord = {
    initial: {
        opacity: 0,
        y: 50
    },
    animate: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 1
        }
    }
}

const AnimatedText = ({ text, className = "" }) => {
    const { theme } = useTheme();
    return (
        <>              
        <div className='w-full mx-auto flex items-center justify-center text-center overflow-hidden'>
            <motion.h1 className={`text-primary inline-block w-full font-bold captialize text-8xl ${className}`}
                variants={quote}
                initial="initial"
                animate="animate" >
                {
                    text.split(" ").map((word, index) =>
                        <motion.span key={word + '-' + index} className='inline-block'
                            variants={singleWord}>
                            {word}&nbsp;
                        </motion.span>
                    )
                }
            </motion.h1>
        </div>
        </>
    )
}

export default AnimatedText
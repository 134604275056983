import './App.css';
import { createTheme, NextUIProvider } from "@nextui-org/react"
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import useDarkMode from 'use-dark-mode';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Home from './pages/Home';
import Projects from './pages/Projects';
import NavbarComponent from './pages/NavbarComponent';
import Experience from './pages/Experience';
import Education from './pages/Education';
import Skills from './pages/Skills';
import About from './pages/About';

const darkTheme = createTheme({
  type: 'dark',
  theme: {
    colors: { text: '#FFFFFF', }, // override dark theme colors
  }
});

const lightTheme = createTheme({
  type: 'light',
  theme: {
    colors: { text: '#000000', },
  }
})

function App() {
  const darkMode = useDarkMode(true);

  const toggleTheme = () => {
    darkMode.toggle();
  };

  return (
    <NextThemesProvider>
      <NextUIProvider theme={darkTheme}>
        <Router>
          <NavbarComponent toggleTheme={toggleTheme} />
            {/* theme={darkMode.value ? darkTheme : lightTheme} */}
          <Routes>
            <Route path="*" element={<Navigate to="/" />} />
            <Route path='/' element={<Home />} />
            <Route path='/aboutme' element={<About />} />
            <Route path='/projects' element={<Projects />} />
            <Route path='/experience' element={<Experience />} />
            <Route path='/education' element={<Education />} />
            <Route path='/skills' element={<Skills />} />
          </Routes>
        </Router>
      </NextUIProvider>
    </NextThemesProvider>
  );
}

export default App;

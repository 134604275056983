import React, { useState } from "react";
import { Navbar, Link } from "@nextui-org/react";
import { User } from "@nextui-org/react";
import LogoImg from '../assets/images/Adesh.jpeg';
import { useNavigate } from 'react-router-dom';
import { Switch } from '@nextui-org/react'
import { SunIcon } from "./icons/SunIcon";
import { MoonIcon } from "./icons/MoonIcon";
import AdeshGrad from '../assets/images/adeshgrad.png';


export default function NavbarComponent({ toggleTheme }) {
    const navigate = useNavigate();
    const variant = 'sticky';
    const [activeLink, setActiveLink] = useState('');

    const collapseItems = [
        "About",
        "Education",
        "Experience",
        "Projects",
        "Tech Stack",
    ];

    const [activeIndex, setActiveIndex] = useState(0);


    const handleLinkClick = (link) => {
        setActiveLink(link);
        if (link === 'Home') {
            navigate('/');
        }
        if (link === 'About') {
            navigate('/aboutme');
        }
        if (link === 'Projects') {
            navigate('/projects');
        }
        if (link === 'Experience') {
            navigate('/experience');
        }
        if (link === 'Education') {
            navigate('/education');
        }
        if (link === 'Skills') {
            navigate('/skills');
        }
    };

    const handleHamburgerLinkClick = (index) => {
        setActiveIndex(index);
        if (index === 0) {
            navigate('/aboutme');
        }
        if (index === 1) {
            navigate('/education');
        }
        if (index === 2) {
            navigate('/experience');
        }
        if (index === 3) {
            navigate('/projects');
        }
        if (index === '4') {
            navigate('/skills');
        }
    };

    return (
        <>
            <Navbar isBordered variant={variant}>
                <Navbar.Toggle showIn="xs" />
                <Navbar.Brand onClick={() => handleLinkClick('Home')}>
                    <User
                        src={AdeshGrad}
                        name="Adesh Landge"
                        zoomed
                        squared
                        description="Seasoned Salesforce Developer | Expertise in Customizing CRM Solutions to Drive Sales & Customer Engagement"
                        size="xl"
                    />
                    {/* <Text blockquote color="inherit" hideIn="xs">
                      Adesh Landge
                    </Text> */}
                </Navbar.Brand>
                <Navbar.Content  activeColor="secondary" variant="highlight-rounded" hideIn="xs">
                    <Navbar.Link
                        isActive={activeLink === 'About'}
                        onClick={() => handleLinkClick('About')}
                    >
                        About
                    </Navbar.Link>
                    <Navbar.Link
                        isActive={activeLink === 'Education'}
                        onClick={() => handleLinkClick('Education')}
                    >
                        Education
                    </Navbar.Link>
                    <Navbar.Link
                        isActive={activeLink === 'Experience'}
                        onClick={() => handleLinkClick('Experience')}
                    >
                        Experience
                    </Navbar.Link>
                    <Navbar.Link
                        isActive={activeLink === 'Projects'}
                        onClick={() => handleLinkClick('Projects')}
                    >
                        Projects
                    </Navbar.Link>
                    <Navbar.Link
                        isActive={activeLink === 'Skills'}
                        onClick={() => handleLinkClick('Skills')}
                    >
                        Skills
                    </Navbar.Link>
                    {/* <Switch
                        shadow
                        checked={true}
                        size="md"
                        iconOn={<SunIcon filled />}
                        iconOff={<MoonIcon filled />}
                        onChange={toggleTheme}
                    /> */}
                </Navbar.Content>
                <Navbar.Collapse >
                    {collapseItems.map((item, index) => (
                        <Navbar.CollapseItem
                            key={item}
                            activeColor="warning"
                            isActive={index === activeIndex}
                        >
                            <Link
                                color="inherit"
                                css={{
                                    minWidth: "100%",
                                }}
                                href="#"
                                onClick={() => handleHamburgerLinkClick(index)}
                            >
                                {item}
                            </Link>
                        </Navbar.CollapseItem>
                    ))}
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}

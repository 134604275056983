import React from 'react'
import AnimatedText from './AnimatedText'
import { Image, Link } from '@nextui-org/react';
import LogoImg from '../assets/images/Land1.jpeg';
import { GoMarkGithub } from "react-icons/go";
import Layout from './Layout';
import Wiors from '../assets/images/WIORS.png';
import Yelp from '../assets/images/Yelp.webp';
import StudentCorner from '../assets/images/SC.png';
import HealthClub from '../assets/images/HC.png';
import UtilityApp from '../assets/images/humanintheloop.jpg';
import Macroeconomic from '../assets/images/macroeconomic.png';
import Stock from '../assets/images/stock.jpg';
//import TransitionEffect from './TransitionEffect';


const FeaturedProject = ({ type, title, summary, img, link, github }) => {
  return (
    <article className='w-full flex items-center justify-between rounded-3xl border border-solid border-dark bg-light
    shadow-2xl p-12 relative rounded-br-2xl'>
      <div className='absolute top-0 -right-3 -z-10 w-[101%] h-[103%] rounded-[2.5rem] bg-dark rounded-br-3xl'></div>
      <Link href={link} target='_blank' className='w-1/2 cursor-pointer overflow-hidden rounded-lg'>
        <Image showSkeleton src={img} alt={title} className='w-full h-auto'></Image>
      </Link>
      <div className='w-1/2 flex flex-col items-start justify-between pl-6'>
        <span className='text-primary font-medium text-xl'>{type}</span>
        <Link style={{ color: 'black' }} href={link} target='_blank' className='hover:underline underline-offset-2'>
          <h2 className='my-2 w-full text-left text-4xl font-bold'>{title}</h2>
        </Link>
        <p className='my-2 font-medium text-dark'>{summary}</p>
        <div className='mt-2 flex items-center'>
          <Link href={github} target='_blank' style={{ color: 'black', fontSize: '2.5em' }} className='bg-dark w-full cursor-pointer'>
            <GoMarkGithub />
          </Link>
          {/* <Link href={link} style={{ color: 'black' }} target='_blank'
            className='ml-4 rounded-lg bg-dark text-light p-2 px-6 text-lg font-semibold'>
            Visit Project
          </Link> */}
        </div>
      </div>
    </article>
  );
}

const Project = ({ type, title, summary, img, link, github }) => {
  return (
    <article className='w-full flex flex-col items-center justify-center rounded-2xl border border-solid border-dark bg-light
    shadow-2xl p-6 relative'>
      <div className='absolute top-0 -right-3 -z-10 w-[101%] h-[103%] rounded-[2rem] bg-dark rounded-br-3xl'></div>
      <Link href={github} target='_blank' className='w-full cursor-pointer overflow-hidden rounded-lg'>
        <Image showSkeleton src={img} alt={title} className='w-full h-auto'></Image>
      </Link>
      <div className='w-full flex flex-col items-start justify-between mt-4'>
        <span className='text-primary font-medium text-xl'>{type}</span>
        <Link style={{ color: 'black' }} href={link} target='_blank' className='hover:underline underline-offset-2'>
          <h2 className='my-2 w-full text-left text-3xl font-bold'>{title}</h2>
        </Link>
        <p className='my-2 font-medium text-dark'>{summary}</p>
        <div className='w-full mt-2 flex items-center justify-between'>
          {/* <Link style={{ color: 'black' }} href={link} target='_blank'
            className='underline text-lg font-semibold'>
            Visit
          </Link> */}
          <Link style={{ color: 'black', fontSize: '1.5em' }} href={github} target='_blank' className='bg-dark w-8 cursor-pointer'>
            <GoMarkGithub />
          </Link>
        </div>
      </div>
    </article>
  );
}

const Projects = () => {
  return (
    <>
      {/* <TransitionEffect /> */}
      <main className='w-full flex flex-col items-center justify-center'>
        {/* className='mt-16 */}
        <AnimatedText text="Projects" className='mt-10' />
        <Layout>
          <div className="grid grid-cols-12 gap-24 gap-y-32">
            <div className="col-span-12">
              <FeaturedProject
                title="Work In Office Reservation System"
                summary="Full-stack application for employees to make seat reservations, view their compliance data, view the dashboard, specify a get-together day, and view the employee-attendance metrics. Employers can manage the total reservable seats and specify the minimum no. of days employees must visit the office."
                link="https://github.com/adeshlandge/WIORS"
                github="https://github.com/adeshlandge/WIORS"
                type="ReactJS | Spring Boot application"
                img={Wiors}
              />
            </div>
            <div className="col-span-6">
              <Project
                title="Healthclub Management Application"
                summary="Health club web application using React, Spring Boot, MySQL, and AWS services. Users can view, book classes, and track activities across multiple locations, while gym admins can manage members, enroll new ones, and access analytics.
                "
                link="https://github.com/adeshlandge/Healthclub-Application"
                github="https://github.com/adeshlandge/Healthclub-Application"
                type="ReactJS | Spring Boot application"
                img={HealthClub}
              />
            </div>
            <div className="col-span-6">
              <Project
                title="Student Corner"
                summary="A fully-fledged, scalable, fault-tolerant, and distributed web application using Kafka as the middleware. community amongst students, where students can post housing opportunities, find sublets, form groups, share study material and message one another."
                link="https://github.com/adeshlandge/student-corner"
                github="https://github.com/adeshlandge/student-corner"
                type="MERN | Kafka application"
                img={StudentCorner}
              />
            </div>
            <div className="col-span-6">
              <Project
                title="Restaurant Success Prediction"
                summary="Analyzed success factors for US restaurants, identifying popular cuisines, locations, and their correlation with success. Used classification models and analyzed location/cuisine impact. Explored noise levels, demographics, footfall patterns, and closure rates. Presented findings and recommendations for informed decisions to optimize success."
                link="https://github.com/adeshlandge/RESTAURANT-SUCCESS-PREDICTION-BASED-ON-YELP-DATASET"
                github="https://github.com/adeshlandge/RESTAURANT-SUCCESS-PREDICTION-BASED-ON-YELP-DATASET"
                type="Python | Machine Learning"
                img={Yelp}
              />
            </div>
            <div className="col-span-6">
              <Project
                title="Applying Machine Learning and Crowdsourcing in Utility Management Services"
                summary="Ideated and developed a cloud-based automation system designed for non-smart meters, utilizing deep learning-based image recognition techniques, and combining it with a human-in-the-loop verification framework based on crowd-sourcing and continuous retraining from the images uploaded by the users."
                link=""
                github=""
                type="Computer Vision | Machine Learning | MLOps | Mobile App"
                img={UtilityApp}
              />
            </div>
            <div className="col-span-6">
              <Project
                title="Macroeconomic Researcher Food Security Time Series and Large Language Chat GPT Dashboard"
                summary="An interactive data visualization tool that offers time series analysis for various countries. Features include drag-and-drop functionality for indicators, annotation support, and natural language interaction with data. Integrates ChatGPT for enhanced insights, and chatbot for support related to economy and food security."
                link="https://github.com/adeshlandge/Macroeconomic-Researcher-Food-Security-Time-Series-and-Large-Language-Chat-GPT-Dashboard"
                github="https://github.com/adeshlandge/Macroeconomic-Researcher-Food-Security-Time-Series-and-Large-Language-Chat-GPT-Dashboard"
                type="Python | Flask | ChatGPT"
                img={Macroeconomic}
              />
            </div>
            <div className="col-span-6">
              <Project
                title="Stock Portfolio Suggestion Engine"
                summary="Stock Portfolio Suggestion Engine: This project offers tailored stock and ETF recommendations based on user-defined investment strategies. Users input a minimum of $5000 USD and select from strategies like Ethical, Growth, Index, Quality, or Value Investing. The engine maps each strategy to specific stocks or ETFs, ensuring a diversified selection. Outputs include chosen stocks, allocation of funds, real-time portfolio value, and a five-day trend history of overall portfolio performance."
                link=""
                github=""
                type="Python | Flask | React"
                img={Stock}
              />
            </div>
          </div>
        </Layout>
      </main>
    </>
  )
}

export default Projects
